import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignature } from '@fortawesome/free-solid-svg-icons'
import './Overview.css';

const Overview = (props) => {
    const players = Array.from(new Set(props.players));
    let cost = 0;
    players.forEach(player => {
        cost += player.salary;
    });
    return (
        <div>
            <h3>Overview</h3>
            <h5>Player selected: <span class="overview-highlight">{players.length}</span></h5>
            <h5>Selected players:</h5>
            <ol>
                {
                    players.map(player => <li>{player.name} (€{player.salary}M)</li>)
                }
            </ol>
            <h5>Total cost: <span class="overview-highlight">€{cost}M</span></h5>
            <button type="button" onClick={() => alert('Do you have that much money?')} className="btn btn-dark"><FontAwesomeIcon icon={faSignature} /> Continue signing</button>
        </div>
    );
};

export default Overview;