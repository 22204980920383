import React from 'react';
import './Header.css';

const Header = () => {
    return (
        <header>
            <nav className="navbar-dark bg-dark" id="header">
                <h2 className="text-center">Signing Players</h2>
            </nav>
        </header>
    );
};

export default Header;