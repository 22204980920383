import React from 'react';
import './Player.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

const Player = (props) => {
    const {name, img, salary} = props.player;
    return (
        <div className="col-md-4">
            <div className="card row g-0" id="player">
                <div className="col-md-6">
                    <img src={img} alt="..." className="card-img-top w-75" />
                </div>
                <div className="col-md-6">
                    <h4>{name}</h4>
                    <h5>Salary: €{salary}M</h5>
                    <button type="button" onClick={() => props.handleBuy(props.player)} className="btn btn-dark"><FontAwesomeIcon icon={faCheck} /> Select</button>
                </div>
            </div>
        </div>
    );
};

export default Player;