import React, { useEffect, useState } from 'react';
import fakeData from '../../fakeData/players.json';
import Overview from '../Overview/Overview';
import Player from '../Player/Player';
import './Home.css';

const Home = () => {
    const [overview, setOverview] = useState([]);
    const [players, setPlayers] = useState([]);
    useEffect(() => {
        setPlayers(fakeData)
    }, [])
 
    const handleBuy = (player) => {
        const newOverview = [...overview, player];
        setOverview(newOverview)
    }
    return (
        <div className="mt-4 row">
            <div className="col-md-10">
                <div className="container">
                    <div className='row row-cols-1 row-cols-md-3 g-4'>
                        {
                            players.map(player => <Player player={player} handleBuy={handleBuy} key={player.id}></Player>)
                        }
                    </div>
                </div>
            </div>
            <div className="col-md-2">
                <Overview players={overview}></Overview>
            </div>
        </div>
    );
};

export default Home;